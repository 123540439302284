.toast-container {
  position: fixed;
  top: 2rem;
  left: 50%;
  /* 设置左边距为50% */
  transform: translateX(-50%);
  /* 向左移动自身宽度的50%，实现水平居中 */
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.toast {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 300px;
  max-width: 500px;
  animation: slideDown 0.3s ease-out;
  background-color: white;
  /* 确保有背景色 */
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* 保持其他样式不变 */
.toast-success {
  background-color: #f0fdf4;
  border: 1px solid #bbf7d0;
  color: #166534;
}

.toast-error {
  background-color: #fef2f2;
  border: 1px solid #fecaca;
  color: #991b1b;
}

.toast-warning {
  background-color: #fffbeb;
  border: 1px solid #fef3c7;
  color: #92400e;
}

.toast-info {
  background-color: #eff6ff;
  border: 1px solid #bfdbfe;
  color: #1e40af;
}

.toast-message {
  flex: 1;
}

.toast-close {
  padding: 0.25rem;
  border-radius: 50%;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-close:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.toast-icon {
  width: 20px;
  height: 20px;
}