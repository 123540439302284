@import '../../../assets/styles/common.scss';

.effect_flower_top {
    position: absolute;
    top: fluid-width(94);
    left: fluid-width(28);
    width: fluid-width(32);
    height: fluid-width(32);
}

.city_rank_button {
    position: fixed;
    top: fluid-width(197);
    right: 0;
    width: fluid-width(48);
    height: fluid-width(141);
}

.rule {
    margin-top: fluid-width(285);
    margin-left: fluid-width(-12);
    width: fluid-width(72);
    height: fluid-width(30);
}

.effect_flower_bottom {
    position: absolute;
    top: fluid-width(520);
    left: fluid-width(26);
    width: fluid-width(32);
    height: fluid-width(32);
}

.stage {
    margin-top: fluid-width(81.83);
    width: fluid-width(277);
    height: fluid-width(32);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .stage_image {
        width: fluid-width(53);
        height: fluid-width(34);
    }

    .stage_separator {
        width: 1px;
        height: fluid-width(14);
        background-color: #8E1414;
    }
}

.tips_and_task {
    height: fluid-width(302);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .tips_and_task_container {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .tips_img {
            width: 100vw;
        }

        .tasks {

            margin-top: fluid-width(19);
            width: 100vw;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .tasks_tips {

                color: #8E1414;
                font-weight: 600;
                font-size: fluid-width(16);

                margin-top: fluid-width(14);
                margin-left: fluid-width(78);
                width: fluid-width(14);
                margin-right: fluid-width(26);
            }

            .task_list {

                display: grid;
                grid-gap: fluid-width(6);
            }

            .task_item {

                position: relative;

                width: fluid-width(242);
                border-radius: 5px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;

                .task_item_first_line {

                    margin-top: fluid-width(4);
                    margin-left: fluid-width(12);
                    margin-right: fluid-width(12);
                    color: #8E1414;
                    font-size: fluid-width(16);
                    font-weight: 600;
                }

                .task_item_second_line {

                    margin-bottom: fluid-width(6);
                    margin-left: fluid-width(12);
                    color: #8E1414;
                    font-size: fluid-width(14);
                    font-weight: 600;
                }

                .item_button {

                    position: absolute;
                    right: fluid-width(9);
                    bottom: fluid-width(6);

                    width: fluid-width(74);
                    height: fluid-width(23.5);

                    text-align: center;
                    font-size: fluid-width(12);
                    color: #FFF8DC;
                    font-weight: 600;
                    line-height: fluid-width(23.5);

                    background-image: url(./images/task_button_bg.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }

            .task_item_big {
                background-image: url(./images/task_bg_big.png);
            }

            .task_item_small {
                background-image: url(./images/task_bg_small.png);
            }

            .task_item_89 {
                height: fluid-width(89);
            }

            .task_item_72 {
                height: fluid-width(72);
            }

            .task_item_51 {
                height: fluid-width(51);
            }
        }

        .star {
            width: fluid-width(24);
        }

        .small_text {

            color: #8E1414;
            font-weight: 700;
            font-size: fluid-width(26);
            line-height: fluid-width(34);
            text-shadow: 2px 2px 4px #FFE05F;
        }

        .big_text {

            color: #8E1414;
            font-weight: 700;
            font-size: fluid-width(32);
            text-shadow: 2px 2px 4px #FFE05F;
        }

        .rank_container {

            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-top: fluid-width(6);
            height: fluid-width(33.8);

            .rank_container_bg {

                position: absolute;
                top: 0;
                height: fluid-width(34);

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .border_image_1_to_3 {
                width: fluid-width(264);
                background-image: url(./images/rank_bg_1_to_3.png);
            }

            .border_image_4_to_6 {
                width: fluid-width(264);
                background-image: url(./images/rank_bg_4_to_6.png);
            }

            .border_image_6_to_end {
                width: fluid-width(264);
                background-image: url(./images/rank_bg_6_to_end.png);
            }

            .rank_container_text {
                z-index: 0;
                margin-top: fluid-width(7);

                font-size: fluid-width(16);
                font-weight: 600;
                font-family: 'zihunbiantaoti';
            }
        }

        .vote_button {

            position: relative;

            margin-top: fluid-width(23);
            width: fluid-width(222);
            height: fluid-width(34);

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .vote_button_bg {

                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                width: fluid-width(222);
                height: fluid-width(34);
            }

            .vote_button_text {
                z-index: 0;
                font-size: fluid-width(16);
                font-weight: 600;
                color: #FFF8DC;

                text-align: center;
                width: fluid-width(222);
            }
        }
    }
}

.option_popup {

    transform: scale(1.4);

    position: relative;

    width: fluid-width(252);
    height: fluid-width(331);

    background-color: #D12D26;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .option_popup_content {

        width: fluid-width(228);
        height: fluid-width(307);

        background-color: #FFF8DC;
        border-radius: 2px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .option_popup_content_container {

            width: fluid-width(114);
            height: fluid-width(251);

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            overflow-y: scroll;
            scrollbar-width: none;

            .option_popup_content_container_item {

                width: fluid-width(110);
                height: fluid-width(30);

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .option_popup_content_container_item_text {
                    width: fluid-width(74);
                    height: fluid-width(30);
                    text-align: center;
                    font-weight: 600;
                }

                .option_popup_content_container_item_text05 {
                    line-height: fluid-width(30);
                    font-size: fluid-width(14);
                }

                .option_popup_content_container_item_text6 {
                    line-height: fluid-width(30);
                    font-size: fluid-width(11);
                }

                .option_popup_content_container_item_text7 {
                    line-height: fluid-width(30);
                    font-size: fluid-width(9);
                }

                .option_popup_content_container_item_text8 {
                    line-height: fluid-width(30);
                    font-size: fluid-width(9);
                }

                .option_popup_content_container_item_text9 {
                    margin-top: fluid-width(8);
                    line-height: fluid-width(11);
                    font-size: fluid-width(10);
                }

                .option_popup_content_container_item_text10 {
                    margin-top: fluid-width(8);
                    line-height: fluid-width(11);
                    font-size: fluid-width(10);
                }
            }

            .option_popup_content_container_itemBg {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .option_popup_content_container_itemBgLeft {
                background-image: url('./images/rank_filter_left_bg.png');
            }

            .option_popup_content_container_itemBgRight {
                background-image: url('./images/rank_filter_right_bg.png');
            }
        }
    }
}

.h5_city {

    margin-top: fluid-width(300);

    color: #FFE05F;
    font-weight: 600;
    font-size: fluid-width(26);
    line-height: fluid-width(41);
    text-shadow: 2px 2px 4px #8E1414;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .h5_city_exchange {

        width: fluid-width(24);
        height: fluid-width(24);
    }
}

.h5_city_like_button {
    margin-top: fluid-width(44);
    margin-bottom: fluid-width(40);
    width: fluid-width(180);
    height: fluid-width(56);
}

.h5_like_tips {
    margin-top: fluid-width(295);

    width: fluid-width(205);
    height: fluid-width(46);
}

.h5_search_city {
    margin-top: fluid-width(65);
    margin-bottom: fluid-width(41);

    width: fluid-width(245);
    height: fluid-width(34);
}

.like_popup {

    position: relative;

    width: fluid-width(252);
    height: fluid-width(331);

    background-color: #D12D26;
    border-radius: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .like_popup_flower_left {

        position: absolute;
        top: fluid-width(100);
        left: -7px;
        width: fluid-width(32);
        height: fluid-width(32);
    }

    .like_popup_flower_right {

        position: absolute;
        top: fluid-width(78);
        right: -7px;
        width: fluid-width(32);
        height: fluid-width(32);
    }

    .like_popup_content {

        width: fluid-width(191);
        height: fluid-width(307);
        padding-left: fluid-width(25);
        padding-right: fluid-width(12);

        background-color: #FFF8DC;
        border-radius: 2px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .like_popup_content_tips {

            margin-top: fluid-width(64);
            height: fluid-width(40);
        }

        .like_popup_content_tips_2 {

            margin-top: fluid-width(3);
            height: fluid-width(28);
        }

        .like_popup_content_tips_3 {

            margin-top: fluid-width(4);
            height: fluid-width(133);

            color: #8E1414;
            font-weight: 600;
            font-size: fluid-width(14);
            line-height: fluid-width(20);
        }

        .like_popup_content_button {
            height: fluid-width(23.11);
        }
    }
}