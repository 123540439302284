/* 页面容器 */
.page {

    position: relative;

    margin: 0;
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: black;

    /* 背景区域 */
    .page_bg {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .page_bg_image {
            width: 100vw;
            height: auto;
        }
    }

    /* 背景区域 */
    .page_content {

        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        width: 100vw;
        min-height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    /* 弹窗区域 */
    .page_popup {

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        touch-action: none;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: rgba(0, 0, 0, 0.5);
    }

    /* loading */
    .page_loading {

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 100;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .page_loading_text {

            border-radius: 5px;
            padding: 10px;
            color: white;
            background-color: rgb(0, 0, 0, 0.6);
        }
    }
}