@import '../../../../assets/styles/common.scss';

.retry_popup {
    width: fluid-width(207);
    height: fluid-width(237);

    background-color: white;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .retry_popup_message {
        padding: 0 10px;
        text-align: center;
        color: #383838;
        font-size: fluid-width(16);
        font-weight: 400;
    }

    .retry_popup_button {

        min-width: fluid-width(127);
        height: fluid-width(30);

        color: white;
        font-size: fluid-width(18);
        line-height: fluid-width(30);
        font-weight: 600;
        text-align: center;
        padding: 0 10px;
        background-color: #EA562D;
        border-radius: 15px;
    }
}
