@import '../../../../assets/styles/common.scss';

.container {
    position: relative;
    width: fluid-width(376);
    border-radius: 18px;
    background-color: #FFF8DC;
    margin-bottom: 32px;
    padding-bottom: 22px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .previous_rank_container {

        position: absolute;
        top: fluid-width(-90);
        width: fluid-width(376);
        height: fluid-width(98);

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .previous_rank_container_bg {

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: fluid-width(98);
        }

        .previous_rank_container_text {
            z-index: 0;
        }

        .previous_rank_container_text_top_container {

            width: fluid-width(349);
            line-height: fluid-width(30);

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .previous_rank_container_text_top {

            font-size: fluid-width(13);
            font-weight: 600;
            color: #8E1414;
            line-height: fluid-width(30);
        }

        .previous_rank_container_text_top_count {

            font-size: fluid-width(13);
            font-weight: 600;
            color: #FFE05F;
            line-height: fluid-width(30);
            text-shadow:
                -1px -1px 0 #8E1414,
                1px -1px 0 #8E1414,
                -1px 1px 0 #8E1414,
                1px 1px 0 #8E1414;
        }

        .previous_rank_container_text_bottom {

            color: #FFE05F;
            font-size: fluid-width(26);
            font-weight: 600;
            text-align: center;
            line-height: fluid-width(68);
            text-shadow: 2px 2px 4px #8E1414;

            white-space: nowrap;
            width: fit-content;
            transform-origin: center;
        }
    }

    .rank_button {
        margin-top: fluid-width(31);
        width: fluid-width(224);
        height: fluid-width(34);

        color: #FFF8DC;
        font-size: fluid-width(16);
        font-weight: 600;
        line-height: fluid-width(34);
        text-align: center;

        background-image: url('../images/effect_rank_change_button.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .exchange {
            width: fluid-width(24);
            height: fluid-width(24);
            margin-right: fluid-width(10);
        }
    }

    .note_list> :first-child {
        margin-left: fluid-width(12);
    }

    .note_list> :last-child {
        margin-right: fluid-width(12);
    }

    .note_list {

        margin-top: fluid-width(23);
        width: fluid-width(363);
        height: fluid-width(222);

        background-image: url('../images/note_list_bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        display: grid;
        grid-auto-flow: column;
        /* 横向排列 */
        grid-auto-columns: min-content;
        /* 根据内容宽度自动计算 */
        overflow-x: auto;
        gap: 6px;
        scrollbar-width: none;

        .note_container {

            position: relative;

            margin-top: fluid-width(33);
            width: fluid-width(135);
            height: fluid-width(180);
            border-radius: 5px;
            overflow: hidden;

            .note_cover {
                position: absolute;
                top: 0;
                left: 0;
                width: fluid-width(135);
                height: fluid-width(180);
                object-fit: cover;
                object-position: center;
            }

            .note_info {
                position: absolute;
                bottom: 0;
                left: 0;
                width: fluid-width(135);
                height: fluid-width(46);

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                backdrop-filter: blur(4.5px);
                /* 兼容 Safari */
                -webkit-backdrop-filter: blur(4.5px);
                /* 常配合半透明背景使用 */
                background-color: rgba(0, 0, 0, 0.3);
            }

            .note_avatar {
                margin-left: 6px;
                width: fluid-width(30);
                height: fluid-width(30);
                border-radius: fluid-width(15);
            }

            .note_title {
                font-size: fluid-width(10);
                font-weight: 600;
                color: white;
                margin-left: 6px;
                margin-right: 6px;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .rank_top_5 {

        position: relative;
        margin-top: fluid-width(18);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: end;

        .rank_item_info {

            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            padding-top: fluid-width(48);

            .rank_item_heart {
                position: absolute;
                top: 0;
                width: fluid-width(200);
                text-align: center;

                color: #8E1414;
                font-size: fluid-width(12);
                font-weight: 600;
            }

            .rank_item_name {
                position: absolute;
                top: fluid-width(17);
                width: fluid-width(200);
                text-align: center;

                color: #8E1414;
                font-size: fluid-width(20);
                font-weight: 600;
            }

            .rank_snake_1 {
                margin: 0 fluid-width(13);
                width: fluid-width(55.43);
                height: auto;
            }

            .rank_snake_2 {
                margin: 0 fluid-width(13);
                width: fluid-width(45.61);
                height: auto;
            }

            .rank_snake_3 {
                margin: 0 fluid-width(13);
                width: fluid-width(42.57);
                height: auto;
            }

            .rank_snake_4 {
                margin: 0 fluid-width(13);
                width: fluid-width(33.84);
                height: auto;
            }

            .rank_snake_5 {
                margin: 0 fluid-width(13);
                width: fluid-width(26.56);
                height: auto;
            }

            .rank_item_hand {

                position: absolute;

                width: fluid-width(80);
                height: fluid-width(29);
            }

            .rank_item_hand_1 {
                margin-left: fluid-width(-18);
                top: fluid-width(156);
            }

            .rank_item_hand_2 {
                top: fluid-width(146);
            }

            .rank_item_hand_3 {
                top: fluid-width(127);
            }

            .rank_item_hand_4 {
                top: fluid-width(120);
            }

            .rank_item_hand_5 {
                top: fluid-width(90);
            }

            .rank_title {
                position: absolute;
                padding-top: fluid-width(11);
                padding-left: fluid-width(2);
                width: fluid-width(82);
                text-align: center;

                font-size: fluid-width(14);
                font-weight: 600;
                color: #FFE05F;
            }
        }

        .rank_other_title {

            position: absolute;
            bottom: fluid-width(-48);

            color: #F20C0C;
            font-size: fluid-width(16);
            margin-top: fluid-width(30);
            height: fluid-width(18);
            line-height: fluid-width(18);
            border-radius: 5px;
            background-color: #FEC64C;
            padding: fluid-width(4);
            font-weight: 600;
        }
    }

    .other_rank_list {

        position: relative;
        margin-top: fluid-width(60);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: fluid-width(17);

        .other_rank_item {

            position: relative;
            width: fluid-width(76.61);
            height: fluid-width(108);

            .other_rank_item_order {

                position: absolute;
                left: fluid-width(6);
                width: fluid-width(22);
                text-align: center;
                color: #8E1414;
                font-size: fluid-width(12);
                font-weight: 600;
            }

            .other_rank_item_name {

                position: absolute;
                top: fluid-width(40);
                text-align: center;
                left: 0;
                right: 0;
                text-align: center;
                color: #8E1414;
                font-size: fluid-width(16);
                font-weight: 600;
            }

            .other_rank_item_heart {

                position: absolute;
                bottom: 0;
                text-align: center;
                left: 0;
                right: 0;
                color: #8E1414;
                font-size: fluid-width(12);
                line-height: fluid-width(17);
                font-weight: 600;
            }
        }

        .other_rank_item_bg_style {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top center;
        }

        .other_rank_item_bg_1 {
            background-image: url('../images/other_rank_item_bg_1.png');
        }

        .other_rank_item_bg_2 {
            background-image: url('../images/other_rank_item_bg_2.png');
        }

        .other_rank_item_bg_3 {
            background-image: url('../images/other_rank_item_bg_3.png');
        }
    }
}

.container_margin_top_28 {
    margin-top: fluid-width(28);
}

.container_margin_top_104 {
    margin-top: fluid-width(104);
}