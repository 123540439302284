/* 当屏幕宽度小于1440px时启用水平滚动 */
@media screen and (max-width: 1440px) {

    body,
    html {
        overflow-x: auto;
    }

    .homepage {
        width: 1440px;
    }
}

/* 添加页面过渡动画 */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.homepage {
    text-align: center;
    color: #333;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    min-width: 1440px;
}

/* Header 样式 */
.header {
    background-color: #EA562D;
    color: #fff;
    padding: 1rem 0;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: all 0.3s ease;
}

.navbar {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
    align-items: center;
}

.logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.logo img {
    height: 40px;
    width: auto;
    display: block;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.menu {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.menu span {
    margin: 0 1rem;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    padding: 0.5rem 0;
    position: relative;
}

.menu span::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #ffd700;
    transition: width 0.3s ease;
}

.menu span.active::after {
    width: 100%;
}

.menu span:hover {
    color: #ffd700;
}

/* Banner 样式 */
.banner {
    height: 800px;
    background: linear-gradient(to bottom, #EA562D, #FFFFFF);
    overflow: hidden;
    display: flex;
    padding-top: 50px;
}

.background {
    width: 80%;
    height: 400px;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.bannerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.background .title {
    font-size: 5.2rem;
    letter-spacing: 0.6rem;
    font-weight: 600;
    color: #000;
    font-family: "Noto Sans SC", "HarmonyOS Sans SC", "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei", sans-serif;
    animation: fadeInUp 1.2s ease-out;
    position: relative;
    line-height: 1.4;
    margin-bottom: 20px;
}

.downloadContainer {
    display: flex;
    align-items: center;
    animation: fadeInUp 1.4s ease-out;
}

.qrCode {
    width: 160px;
    padding: 12px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.qrImage {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

.appStoreImage {
    width: 110px;
    height: auto;
    display: block;
    margin: 0;
}

/* 云朵和行李箱动画 */
@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes suitcaseWiggle {
    0% {
        transform: rotate(16.57deg);
    }

    50% {
        transform: rotate(20.57deg);
    }

    100% {
        transform: rotate(16.57deg);
    }
}

.cloud {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    animation: float 3s ease-in-out infinite;
}

.cloud.left {
    top: 0;
    left: 10px;
    width: 269.33px;
    height: 161.33px;
    background-image: url('../images/home/cloud-left.svg');
}

.cloud.right {
    top: -40px;
    right: 10px;
    width: 269.33px;
    height: 161.33px;
    background-image: url('../images/home/cloud-right.svg');
    animation-delay: 1.5s;
}

.suitcase {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    animation: suitcaseWiggle 4s ease-in-out infinite;
}

.suitcase.left {
    bottom: 0;
    left: 8px;
    width: 179.79px;
    height: 182.1px;
    transform: rotate(16.57deg);
    background-image: url('../images/home/suitcase-left.svg');
}

.suitcase.right {
    bottom: -80px;
    right: 55px;
    width: 182.67px;
    height: 233.33px;
    transform: rotate(14.66deg);
    background-image: url('../images/home/suitcase-right.svg');
}

/* 内容部分样式 */
.content {
    position: relative;
    height: 600px;
    background-color: #fff;
    padding: 2rem 0;
    width: 100%;
}

.app {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
}

.app.pic1 {
    top: 0;
    left: 0;
    width: 262px;
    height: 568px;
    transform: rotate(-24deg);
    background-image: url('../images/home/app-1.png');
}

.app.pic2 {
    top: -300px;
    left: 170px;
    width: 262px;
    height: 568px;
    transform: rotate(-24deg);
    background-image: url('../images/home/app-2.png');
}

.app.pic3 {
    top: -210px;
    left: 515px;
    width: 262px;
    height: 568px;
    transform: rotate(-24deg);
    background-image: url('../images/home/app-3.png');
}


.content_textarea {
    width: 30%;
    float: right;
    margin-right: 10%;
    text-align: left;
}

.content_textarea h2 {
    font-size: 3rem;
    font-weight: bolder;
    line-height: 0.1;
}

.content_textarea p {
    font-size: 1rem;
    font-weight: bolder;
}

.routePlanning {
    height: 50vh;
    background: linear-gradient(to top, #EA562D, #FFFFFF);
    display: flex;
    position: relative;
    width: 100%;
}

.app.pic4 {
    top: -210px;
    right: 360px;
    width: 262px;
    height: 568px;
    transform: rotate(24deg);
    background-image: url('../images/home/app-4.png');
}

.app.pic5 {
    top: -210px;
    right: 50px;
    width: 262px;
    height: 568px;
    transform: rotate(24deg);
    background-image: url('../images/home/app-5.png');
}

.routePlanning_textarea {
    width: 30%;
    float: left;
    margin-left: 10%;
    text-align: left;
}

.routePlanning_textarea h2 {
    font-size: 3rem;
    font-weight: bolder;
    line-height: 0.1;
}

.routePlanning_textarea p {
    font-size: 1rem;
    font-weight: bolder;
    line-height: 0.1;
}


/* 关于我们部分样式 */
.about {
    min-height: calc(100vh - 72px);
    background: linear-gradient(to bottom, #EA562D, #f9f9f9);
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2rem 10%;
    margin-top: -72px;
}

.aboutContent {
    width: 80%;
    height: 400px;
    margin: 0 auto;
    padding-top: 8%;
    opacity: 1;
}

.aboutContent p {
    text-indent: 2em;
    font-size: 3rem;
    color: #000;
    font-weight: bolder;
    text-align: left;
}

.aboutContent span {
    font-size: 1.5rem;
    color: #000;
    text-decoration: none;
    /* 移除整段文字的下划线，让下划线只出现在链接悬停时 */
    font-weight: bold;
    /* 加粗用于突出"联系我们"文字 */
}

.aboutContent span a {
    color: #EA562D;
    text-decoration: none;
    transition: color 0.3s;
    margin-left: 0.5rem;
    /* 给链接留出一点空白，让文本间隔更舒适 */
}

.aboutContent span a:hover {
    color: #cb3b13;
    /* 悬停时加深颜色，给予点击提示 */
    text-decoration: underline;
}

.aboutContent span a i {
    margin-right: 0.3rem;
    /* 图标与文字之间留白 */
    vertical-align: middle;
    /* 图标与文字垂直对齐 */
    font-size: 1.2rem;
    /* 图标大小适当调整 */
}

.aboutcloud {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;

}

.aboutcloud.left {
    top: 15%;
    left: 10%;
    width: 161px;
    height: 108px;
    background-image: url('../images/home/cloud-left.svg');
}

.aboutcloud.right {
    top: 10%;
    left: 66%;
    width: 161px;
    height: 108px;
    background-image: url('../images/home/cloud-right.svg');
}

.person {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
}

.personLeft {
    left: 5%;
    top: 60%;
    width: 141px;
    height: 235px;
    background-image: url('../images/home/person-left.svg');
}

.personRight {
    right: 10%;
    top: 50%;
    width: 137px;
    height: 209px;
    background-image: url('../images/home/person-right.svg');
}

.footer {
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    color: #999;
    background-color: #f9f9f9;
}

/* 响应式设计 */
@media (max-width: 1024px) {
    .background .title {
        font-size: 4rem;
        letter-spacing: 0.5rem;
    }

    .cloud {
        transform: scale(0.8);
    }

    .content_textarea h2,
    .routePlanning_textarea h2 {
        font-size: 2.5rem;
    }

    .app {
        transform: scale(0.8);
    }
}

@media (max-width: 768px) {
    .header {
        padding: 0.5rem 0;
    }

    .logo img {
        height: 30px;
    }

    .menu span {
        font-size: 0.9rem;
        margin: 0 0.5rem;
    }

    .background .title {
        font-size: 2.8rem;
        letter-spacing: 0.3rem;
    }

    .banner {
        height: 600px;
    }

    .cloud {
        transform: scale(0.6);
    }

    .suitcase {
        transform: scale(0.6);
    }

    .content,
    .routePlanning {
        height: auto;
        padding: 2rem 0;
    }

    .content_textarea,
    .routePlanning_textarea {
        width: 90%;
        float: none;
        margin: 0 auto;
        text-align: center;
        padding: 2rem 0;
    }

    .app {
        position: relative;
        transform: scale(0.6);
        margin: 1rem auto;
    }

    .aboutContent p {
        font-size: 2rem;
    }

    .menu span {
        margin: 0 0.5rem;
        font-size: 0.9rem;
    }

    .menu span::after {
        bottom: -1px;
        height: 1px;
    }
}

/* 添加滚动动画类 */
.fadeInOnScroll {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.6s ease-out;
}

.fadeInOnScroll.needAnimation {
    opacity: 0;
    transform: translateY(20px);
}

.fadeInOnScroll.needAnimation.visible {
    opacity: 1;
    transform: translateY(0);
}

.appStoreButton {
    display: block;
    width: 110px;
    cursor: pointer;
    transition: transform 0.3s ease;
    text-decoration: none;
}

.appStoreButton:hover {
    transform: translateY(-2px);
}

.appStoreImage {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
}

@media (max-width: 1024px) {
    .appStoreButton {
        width: 100px;
    }
}

@media (max-width: 768px) {
    .appStoreButton {
        width: 90px;
    }
}