
@import '../../../assets/styles/common.scss';

body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: auto;
}

/* 页面容器 */
.xcyj_page {
    margin: 0;
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

/* 内容区域 */
.xcyj_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
}

/* 背景区域 */
.xcyj_content_bg {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-center;
    align-items: center;
    flex-wrap: wrap;
}

.xcyj_content_bg_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.xcyj_content_overlay_joinips_rule {
    position: absolute;
    width: fluid-width(72); 
    height: fluid-width(30);  
    padding-top: fluid-width(690); 
}

/* 步骤一容器 */
.xcyj_content_overlay_step_one {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    padding-top: fluid-width(1009); 
}

.xcyj_content_step_one_rate_text {
    position: absolute;
    padding-top: fluid-width(328); 
    padding-left: fluid-width(227); 

    font-family: PingFang SC;
    font-size: fluid-width(31); 
    font-weight: 600;
    line-height: fluid-width(43);
    color: #EA562D;
}

.xcyj_content_step_one_rate_detail_img {
    position: absolute;
    object-fit: contain;
    display: block;
    padding-top: fluid-width(338); 
    padding-left: fluid-width(340); 
    width: fluid-width(24); 
    height: fluid-width(24);
}

.xcyj_content_step_one_qualification_text {
    position: absolute;
    left: 45%;    
    transform: translateX(-50%);
    padding-top: fluid-width(401); 

    font-family: PingFang SC;
    font-weight: 600;
    font-size: fluid-width(15); 
    line-height: fluid-width(21);
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:  fluid-width(250);
}

.xcyj_content_step_one_participate {
    position: absolute; 
    padding-top: fluid-width(398); 
    padding-left: fluid-width(243); 
    width: fluid-width(68); 
    height: fluid-width(30); 
} 


/* 顶部Top */
.xcyj_content_step_one_top_img {
    position: absolute;
    object-fit: contain;
    display: block;
    padding-left: fluid-width(5); 
    width: fluid-width(70); 
    height: fluid-width(46);
}

/* 详情图 */
.xcyj_content_step_one_top_detail_img {
    position: absolute;
    object-fit: contain;
    display: block;
    margin-top: fluid-width(25); 
    width: fluid-width(131); 
    height: fluid-width(175);
}

/* 蒙板 */
.xcyj_content_overlay_step_one_maskplate {
    position: absolute;
    object-fit: contain;
    display: flex;
    align-items: center; /* 垂直居中对齐 */
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4.4px);
    margin-top: fluid-width(152); 
    width: fluid-width(130); 
    height: fluid-width(45);
    border-radius: fluid-width(5); 
}

/* 蒙板头像 */
.xcyj_content_overlay_step_one_maskplate_avatar {
    object-fit: cover; 
    margin-right: fluid-width(5); 
    width: fluid-width(30); 
    height: fluid-width(30);
    border-radius: 15px; 
    padding-left: fluid-width(7); 
}

/* 蒙板内容 */
.xcyj_content_overlay_step_one_maskplate_text {
   
    margin-top: fluid-width(12);
    margin-right: fluid-width(2); 
    height: fluid-width(31); /* 高度 */
    color: #ffffff; /* 字体颜色 */
    font-family: 'PingFang SC'; /* 字体样式 */
    font-weight: 600; /* 字体粗细 */
    font-size: fluid-width(12); /* 字体大小 */
    line-height: fluid-width(15); /* 行高 */
    overflow: hidden; /* 隐藏多余内容 */
    display: -webkit-box; /* 用于限制行数 */
    -webkit-line-clamp: 2; /* 限制为最多两行 */
    -webkit-box-orient: vertical;
    line-clamp: 2;
    text-overflow: ellipsis;
    flex: 1;
}

.xcyj_content_overlay_step_one_top1 {
    position: absolute; 
    padding-top: fluid-width(533); 
    left: 50%;
    transform: translateX(-50%);
    width: fluid-width(131); 
    height: fluid-width(200); 
    border-radius: fluid-width(5); 
} 

.xcyj_content_overlay_step_one_top2 {
    position: absolute; 
    padding-top: fluid-width(588); 
    left: fluid-width(-19);
    transform: rotate(-5.65deg);
    width: fluid-width(131); 
    height: fluid-width(200); 
    border-radius: fluid-width(5); 
} 

.xcyj_content_overlay_step_one_top3 {
    position: absolute; 
    padding-top: fluid-width(575); 
    padding-left: fluid-width(283);
    transform: rotate(5.65deg);
    width: fluid-width(131); 
    height: fluid-width(200); 
    border-radius: fluid-width(5); 
} 

/* 步骤二提问 */
.xcyj_content_step_two_quiz_text {
    position: absolute;
    padding-top: fluid-width(2326); 
    left: fluid-width(40); 
    right: fluid-width(110); 
    font-family: PingFang SC;
    font-size: fluid-width(17); 
    font-weight: 600;
    line-height: fluid-width(26);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}

/* 步骤二投票 */
.xcyj_content_step_two_vote_text {
    position: absolute;
    padding-top: fluid-width(2380); 
    left: fluid-width(40); 
    right: fluid-width(110); 
    font-family: PingFang SC;
    font-size: fluid-width(17); 
    font-weight: 600;
    line-height: fluid-width(26);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}

/* 步骤二笔记图文 */
.xcyj_content_step_two_note_text {
    position: absolute;
    padding-top: fluid-width(2431); 
    left: fluid-width(40); 
    right: fluid-width(110); 
    font-family: PingFang SC;
    font-size: fluid-width(17); 
    font-weight: 600;
    line-height: fluid-width(26);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}

/* 步骤二提问去完成 */
.xcyj_content_step_two_quiz_btn {
    position: absolute;
    padding-top: fluid-width(2322); 
    right: fluid-width(42); 
    width: fluid-width(63); 
    height: fluid-width(30);
}

/* 步骤二投票去完成 */
.xcyj_content_step_two_vote_btn {
    position: absolute;
    padding-top: fluid-width(2377); 
    right: fluid-width(42); 
    width: fluid-width(63); 
    height: fluid-width(30);
}

/* 步骤二发笔记去完成 */
.xcyj_content_step_two_note_btn {
    position: absolute;
    padding-top: fluid-width(2428); 
    right: fluid-width(42); 
    width: fluid-width(63); 
    height: fluid-width(30);
}




/* 步骤三容器 */
.xcyj_content_overlay_step_three {
    position: absolute;
    left: 0;
    width: 98%;
    height: auto;
    object-fit: cover;
    display: block;
    padding-top: fluid-width(2489); 
}

.xcyj_content_overlay_sample_content {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    padding-top: fluid-width(3421); 
}


/* 示例内容_详情图 */
.xcyj_content_overlay_sample_detail_img {
    object-fit: cover;
    width: 100%; 
    height: 100%; 
}

/* 示例内容_蒙板 */
.xcyj_content_overlay_sample_maskplate {
    position: absolute;
    object-fit: cover;
    display: flex;
    align-items: center; /* 垂直居中对齐 */
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5.5px);
    margin-top: fluid-width(162); 
    width: 100%;
    height: fluid-width(56);
    border-radius: fluid-width(5); 
}

/* 示例内容_蒙板头像 */
.xcyj_content_overlay_sample_maskplate_avatar {
    object-fit: cover; 
    margin-right: fluid-width(5); 
    width: fluid-width(30); 
    height: fluid-width(30);
    padding-left: fluid-width(8); 
}

/* 示例内容_蒙板内容 */
.xcyj_content_overlay_sample_maskplate_text {
   
    margin-top: fluid-width(12);
    margin-right: fluid-width(2); 
    height: fluid-width(31); /* 高度 */
    color: #ffffff; /* 字体颜色 */
    font-family: 'PingFang SC'; /* 字体样式 */
    font-weight: 600; /* 字体粗细 */
    font-size: fluid-width(13); /* 字体大小 */
    line-height: fluid-width(18); /* 行高 */
    overflow: hidden; /* 隐藏多余内容 */
    display: -webkit-box; /* 用于限制行数 */
    -webkit-line-clamp: 2; /* 限制为最多两行 */
    -webkit-box-orient: vertical;
    line-clamp: 2;
    text-overflow: ellipsis;
    flex: 1;
}

/* 示例内容一 */
.xcyj_content_overlay_sample_one {
    position: absolute; 
    padding-top: fluid-width(3682); 
    left: fluid-width(43); 
    width: fluid-width(164); 
    height: fluid-width(220); 
    border-radius: fluid-width(5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 示例内容二 */
.xcyj_content_overlay_sample_two {
    position: absolute; 
    padding-top: fluid-width(3682); 
    right: fluid-width(10); 
    width: fluid-width(164); 
    height: fluid-width(220); 
    border-radius: fluid-width(5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 示例内容三 */
.xcyj_content_overlay_sample_three {
    position: absolute; 
    padding-top: fluid-width(4062); 
    left: fluid-width(10); 
    width: fluid-width(164); 
    height: fluid-width(220); 
    border-radius: fluid-width(5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 示例内容四 */
.xcyj_content_overlay_sample_four {
    position: absolute; 
    padding-top: fluid-width(4062); 
    left: fluid-width(185); 
    width: fluid-width(164); 
    height: fluid-width(220); 
    border-radius: fluid-width(5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 示例内容五 */
.xcyj_content_overlay_sample_five {
    position: absolute; 
    padding-top: fluid-width(4423); 
    left: fluid-width(43); 
    width: fluid-width(164); 
    height: fluid-width(220); 
    border-radius: fluid-width(5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 示例内容六 */
.xcyj_content_overlay_sample_six {
    position: absolute; 
    padding-top: fluid-width(4423); 
    right: fluid-width(10); 
    width: fluid-width(164); 
    height: fluid-width(220); 
    border-radius: fluid-width(5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 页脚 */
.footer {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ddd;
}